<template>
  <v-app>
    <div class="ctk-top-bar">
      <v-navigation-drawer v-model="drawer" absolute temporary hide-overlay width="90%">
        <v-list dense nav class="ctk-wap-menu">
          <v-list-item
            v-for="item in menus"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/icons/menu_' + item.icon" class="top-menu-icon">
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app flat absolute class="app-bar ctk-app-bar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
          <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/icons/menu.png" class="top-menu" />
        </v-app-bar-nav-icon>
        <v-toolbar-title class="ctk-bar-title">
          <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/logo.png" class="top-logo" />
        </v-toolbar-title>
      </v-app-bar>
    </div>
    <v-main>
      <keep-alive>
        <router-view ref="router-view"></router-view>
      </keep-alive>
      <Footer />
    </v-main>
    <BottomNav />
  </v-app>
</template>

<script>
import BottomNav from './components/BottomNav.vue'
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer'),
    BottomNav
  },
  data: () => ({
    drawer: null,
    menus: [
      { title: '首页', icon: 'home.png', link: '/' },
      { title: '玛娅历程', icon: 'licheng.png', link: '/history' },
      { title: '玛娅空间', icon: 'kongjian.png', link: '/spaces' },
      { title: '玛娅展厅', icon: 'zhanting.png', link: '/shops' },
      { title: '项目案例', icon: 'anli.png', link: '/cases' },
      { title: '企业动态', icon: 'dongtai.png', link: '/news' },
      { title: '品牌优势', icon: 'youshi.png', link: '/advantage' },
      { title: '招商加盟', icon: 'zhaoshang.png', link: '/join' },
      // { title: '私享家宴', icon: 'jiayan.png', link: '/banquet' },
      { title: '清洁保养', icon: 'baoyang.png', link: '/maintenance' }
    ]
  }),
  mounted () {
    this.activeNav = 3
    this.$store.dispatch('loadInfo')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        document.title = val.title
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector('link[rel*=\'icon\']').setAttribute('href', val.favicon)
      },
      deep: true
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
@import 'sass/variables.scss';

.v-application {
  font-family: $body-font-family  !important;
}
</style>
<style lang="css">
@import 'assets/css/public.css';
</style>
